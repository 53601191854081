import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import DemoBanner from '../components/DemoBanner';
import TextHero from '../components/TextHero';
import BlogDetail from '../components/BlogDetail'
import ResourcesSection from '../components/ResourcesSection';
import SiteMeta from '../components/SiteMeta';
import EmailBanner from '../components/EmailBanner';

const BlogPage = ({ data, pageContext }) => {

  const {
    contentfulBlog: {
      metaTitle,
      metaDescription: {
        metaDescription
      },
      title,
      author,
      sidebarRelatedResource,
      relatedResources,
      form
    },
    contentfulBlogDetailPage: {
      demoCallout
    },
    contentfulHomepage: {
      mailingListCalloutText,
      emailForm,
    }

  } = data;

  const heroData = {
    title: title,
    subtitle: author,
  }

  const emailBannerData = {
    title: mailingListCalloutText,
    form: emailForm,
  };

  return (
    <Layout>
      <SiteMeta description={metaDescription} title={metaTitle} />
      <TextHero {...heroData} variant={'left-aligned'} />
      <BlogDetail {...data.contentfulBlog} form={form} sidebarResource={sidebarRelatedResource} />
      {/* <EmailBanner theme="yellow" {...emailBannerData} /> */}
      <ResourcesSection title={'Related resources'} subtitle={null} featuredResources={relatedResources} />
      <DemoBanner theme="dark" {...demoCallout} />
    </Layout>
  )
}

export default BlogPage;

export const query = graphql`
  query BlogPageQuery($contentful_id : String!) {
    contentfulBlog(contentful_id : { eq: $contentful_id  }){
      ...BlogDetail
      form {
        ...Form
      }
    }
    contentfulBlogDetailPage {
      demoCallout {
        ...Navigator
      }
      form {
        ...Form
      }
    }
    contentfulHomepage {
      mailingListCalloutText
      emailForm {
        ...Form
      }
    }
  }
`;